import React from 'react';
import { useHistory } from 'react-router-dom';
// import Select from 'react-select';

import video from '../../../assets/videos/banner.mp4';
import email from '../../../assets/icons/email.svg';
import password from '../../../assets/icons/password.svg';
// import logo from '../../../assets/images/logos/normal-logo.svg';
import './banner.css';


// type SelectedProps = {
//     name: string;
//     label: string;
//     placeholder: string;
//     readOnly: boolean;
//     options: {
//         label: string;
//         value: string
//     }
// }

const Banner = () => {

    const history = useHistory();

    const notFound = () => {
        window.scrollTo(0, 0)
        history.push('/notfound')
    }

    const onPressBooking = async (event: any) => {
        event.preventDefault();
        window.scrollTo(0, 0)
        // window.location.href = "/dashboard";
        history.push('/details')
    }

    const options = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
    ];

    return (
        <div className='banner'>
            <div className='ssezibwavideo'>
                <div className='background'></div>

                <video
                    src={video}
                    controlsList="nodownload"
                    className='videobg'
                    autoPlay
                    loop
                    muted
                ></video>
            </div>

            {/* <div className="input-list">
                    <button className='btn' onClick={notFound}>View our Blog</button>
                    <button className='btn' onClick={notFound}>View our Blog</button>
                    <button className='btn' onClick={notFound}>View our Blog</button>
                    <button className='btn' onClick={notFound}>View our Blog</button>
                    <button className='button' onClick={notFound}>Book</button>
                </div> */}

            <div className="content">
                <p className="explore">Explore</p>

                <h1>SSEZIBWA FALLS</h1>

                <hr />

                <p>
                    Explore one of the pearl’s hidden treasures and experience an unforgettable scenic beauty of the nature at her waters
                </p>

                <form className="login-form" method="post" onSubmit={onPressBooking}>

                    {/* <p className="form-title">Account</p> */}

                    <div className="form-group input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text first border-right-0" id="basic-addon1">
                                <img src={password} alt="email" className="login-form-icon" />
                            </span>
                        </div>
                        <input
                            type="text"
                            name="email"
                            id="email"
                            className="form-control border-left-0"
                            placeholder="Arrival"
                        // onChange={this.onChangeUsername}
                        />
                    </div>

                    <div className="form-group input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text border-right-0" id="basic-addon1">
                                <img src={password} alt="email" className="login-form-icon" />
                            </span>
                        </div>
                        <input
                            type="text"
                            name="email"
                            id="email"
                            className="form-control border-left-0"
                            placeholder="Departure"
                        // onChange={this.onChangeUsername}
                        />
                    </div>

                    <div className="form-group input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text border-right-0" id="basic-addon1">
                                <img src={password} alt="email" className="login-form-icon" />
                            </span>
                        </div>
                        <input
                            type="text"
                            name="email"
                            id="email"
                            className="form-control border-left-0"
                            placeholder="People"
                            // onChange={this.onChangeUsername}
                        />

                        {/* <Select
                            // type="text"
                            options={options}
                            name="email"
                            id="email"
                            className="form-control border-left-0"
                            placeholder="People"
                            // onChange={this.onChangeUsername}
                        /> */}
                    </div>

                    <div className="form-group input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text border-right-0" id="basic-addon1">
                                <img src={password} alt="email" className="login-form-icon" />
                            </span>
                        </div>
                        <input
                            type="text"
                            name="email"
                            id="email"
                            className="form-control border-left-0"
                            placeholder="Experience"
                        // onChange={this.onChangePassword}
                        />
                    </div>

                    {/* <div className="form-options-wrapper">
                            <div className="login-card-check-box">
                                <input type="checkbox" className="custom-control-input" id="customCheck1" />
                                <label className="custom-control-label" htmlFor="customCheck1">Show password</label>
                            </div>
                            <a href="/notfound" className="text-reset text-link">Forgot password?</a>
                        </div> */}

                    <button className="button" >
                        Book
                    </button>

                </form>

            </div>


        </div>
    )
}

export default Banner;
