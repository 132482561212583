import React from 'react';
import Band from '../../../shared/band/band';
import './acco.css';
// import { useHistory } from 'react-router-dom';
import AjaxPackages from '../../../utils/ajaxPackages';


class Accommodat extends React.Component {

    state = {
        packageList: []
    }

    componentDidMount() {
        // window.addEventListener('scroll', this.handleScroll);
        this.listPackages()
    }

    listPackages = async () => {
        const server_response = await AjaxPackages.listPackages();
        console.log(server_response)
        if (server_response.status === "OK") {
            this.setState({
                packageList: server_response.details.content.list
            })
        } else {
            this.setState({
                packageList: "404",
            })
        }
    }

    render() {

        const packageList = this.state.packageList;

        return (
            <div>
                <Band title="Booking" />

                <div className='the-bandas'>
                    <h2>Our Famous Packages</h2>
                    <hr />
                    <p className='the-paragraph'>
                        The Ssezibwa falls area has a variety of activities, occasions, events and uganda mother nature that it offers and is available for any activities or plans that you might have in mind.
                        Here are a few of the most common booked items in the Ssezibwa Falls enjoyment but remeber, these are not limited by anything.
                    </p>

                    <div className='the-container'>

                        {packageList.map((item, key) =>
                            <div key={item['package_id']} className='the-card'>
                                <div className='the-imgbox'>
                                    <img src={item['package_photo']['file_path']} alt='first-banda' />
                                </div>
                                <div className='the-contentbox'>
                                    <div className='small-bit'>
                                        <h3>{item['package_name']}</h3>
                                        <p>{item['package_description']}</p>
                                        <button
                                            className='btn'
                                        // onClick={goToDetails1}
                                        >View Details</button>
                                    </div>
                                </div>
                            </div>
                        )
                        }

                    </div>
                </div>
            </div>
        )
    }
}

export default Accommodat;

