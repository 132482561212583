import React from 'react';
import Bannar from '../../components/About/bannar/bannar';
import Header from '../../shared/header/Header';
import Footer from '../../shared/footer/Footer';
import Contact from '../../shared/contact/contact';

const AboutView = () => (
  <div>
    <Header />
    <Bannar />
    <Contact />
    <Footer />
  </div>
);

export default AboutView;
