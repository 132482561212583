import React from 'react';
import { Link } from 'react-router-dom';
import you from '../../assets/icons/youtube.png'
import insta from '../../assets/icons/insta.png'
import face from '../../assets/icons/face.png'
import associate from '../../assets/images/association.png'
import asso from '../../assets/images/asso.png'
import tweet from '../../assets/icons/twitter.png'
import ioni from '../../assets/icons/email.png'
import './footer.css'
import FooterNav from '../MobileNav/footerNav';
import logo from '../../assets/ssezibwalogo.png';


const Footer = () => {

    return (
        <div className='footer'>
            <div className='content'>
                <div className='first'>
                    <h3>About Us</h3>
                    <p>
                        The Ssezibwa falls have several rocks with sharp edges which make a sweet hissing sound before falling in a large pool below 14 meters deep. From the falls, River Ssezibwa falls all the way to the wetlands of Lake Victoria and Lake Kyoga. Ssezibwa falls is surrounded by thick vegetation cover which is a home to so many animals.
                        <br /><br />

                        Ssezibwa Falls is uganda's mother nature that aspires to offer the most environmental friendly eco-experience in Uganda while providing our guests with outstanding hospitality and adventures.
                    </p>
                    <div className='icons'>
                        <img src={you} className='footer__icon' alt='youtube' />
                        <img src={insta} className='footer__icon' alt='instagram' />
                        <img src={face} className='footer__icon' alt='facebook' />
                        <img src={tweet} className='footer__icon' alt="twitter" />
                    </div>
                </div>
                <div className='second'>
                    <h3>Quick Links</h3>
                    <FooterNav>
                        <Link to="/" onClick={() => window.scrollTo(0, 0)}>Home</Link>
                        <Link to="/activities" onClick={() => window.scrollTo(0, 0)}>Activities</Link>
                        {/* <Link to="/notfound" onClick={() => window.scrollTo(0, 0)}>Activities</Link> */}
                        <Link to="/book_an_experience" onClick={() => window.scrollTo(0, 0)}>Booking</Link>
                        {/* <Link to="/facilities" onClick={() => window.scrollTo(0, 0)}>Facilities</Link> */}
                        <Link to="/gallery" onClick={() => window.scrollTo(0, 0)}>Gallery</Link>
                        <Link to="/about" onClick={() => window.scrollTo(0, 0)}>About</Link>
                        <Link to="/" onClick={() => window.scrollTo(0, 0)}><img className="logo" src={logo} alt="logo" /></Link>
                    </FooterNav>
                </div>
                <div className='third'>
                    <h3>Members of:</h3>
                    <div className='icons'>
                        <img src={associate} className='footer__image' alt='youtube' />
                        <img src={asso} className='footer__image' alt='instagram' />
                        <img src={face} className='footer__image' alt='facebook' />
                    </div>
                    <p>Ssezibwa Falls requires your feedback so help us by signing up for the newsletter</p>
                    {/* <form action="/action_page.php">
                        <div className="input-container">
                            <img src={ioni} alt='email' className="icon"></img>
                            <input className="input-field" type="text" placeholder="Email" name="email" />
                        </div>
                    </form> */}
                </div>
            </div>
            <div className='copyright'>
                <p>@2020 Ssezibwa Falls. All rights reserved <br />Designed by Thrivetech Uganda.</p>
            </div>
        </div>
    )
}

export default Footer;