import dictionary from "./dictionary";
// const ACCESS_TOKEN = localStorage.getItem('ssezibwa@user');
const apiHost = dictionary.apiHost


const AjaxPackages = {

    async listPackages() {

        // let data = {
        //     "status": status,
        //     "page": page
        // }

        try {
            let response = await fetch(apiHost + 'package/list',
                {
                    method: 'GET',
                    headers: {
                        // 'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    // body: JSON.stringify(),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
}

export default AjaxPackages;
