import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AboutView from '../containers/About/aboutview';
import GalleryView from '../containers/Gallery/galleryview';

import DetailsView from '../containers/Details/detailsview';
import DetailsView1 from '../containers/Details/detailsview1';
import DetailsView2 from '../containers/Details/detailsview2';

import FacilitiesView from '../containers/Facilities/facilitiesview';

import ActivitiesView from '../containers/Activities/activitiesview';
import BookingView from '../containers/Activities/activitiesview';
import AccommodationView from '../containers/Accommodation/accommodationview';
import HomeView from '../containers/Home/homeview';
import Notfoundpage from '../shared/Not';
// import Footer from '../shared/footer/Footer';

const Routes = () => (
    <Router>
        <Switch>
            <Route path="/" exact component={HomeView} />
            <Route path="/about" exact component={AboutView} />
            <Route path="/book_an_experience" exact component={AccommodationView} />
            <Route path="/gallery" exact component={GalleryView} />
            <Route path="/details" exact component={DetailsView} />
            <Route path="/details1" exact component={DetailsView1} />
            <Route path="/details2" exact component={DetailsView2} />
            <Route path="/activities" exact component={ActivitiesView} />
            <Route path="/booking" exact component={BookingView} />
            <Route path="/facilities" exact component={FacilitiesView} />
            <Route path="/notfound" exact component={Notfoundpage} />
        </Switch>
    </Router>
)

export default Routes;
