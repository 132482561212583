import React from 'react';
import Band from '../../../shared/band/band';
import styled from 'styled-components'
import video from '../../../assets/videos/ssezi.mp4'


const Brand = styled.div`

    .the-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 0;
        text-align: center;
    }

    .the-content hr {
        width: 100px;
        border: 1px solid #fec465;
        margin: 35px 0;
    }

    .the-content .the-inside {
        display: flex;
        width: 75%;
        justify-content: space-between;
        align-items: center;
    }

    .the-video,
    .the-text {
        width: 48%;
    }

    .the-text {
        padding: 37px;
    }

    @media (min-width: 320px) and (max-width: 914px) {
        .the-head {
          margin-top: 100px;
        }

        .the-head h1 {
          font-size: 45px;
        }

        .the-content .the-inside {
            width: 100%;
            flex-direction: column;
            margin-top: 0;
        }

        .the-video,
        .the-text {
            width: 90%;
            margin: 0 auto;
        }
    }
`

const Bannar = () => {

    return (
        <Brand>
            <Band title="About" />
            <div className='the-content'>
                <h2>About the Lodge</h2>
                <hr />
                <div className='the-inside'>
                    <p className='the-text'>
                        {/* Being strongly attached to Uganda and Murchison Falls National Park in particular, for more than 20 years,
                        Ssezibwa Falls is a family project that aspires to offer the most environmental friendly eco-lodge in Uganda while providing our guests with outstanding hospitality and adventures.
                        <br />
                        <br />
                        Ssezibwa Falls is a luxurious lodge that provides an unforgettable wildlife experience within the scenic beauty of the Murchison Falls National Park. */}

                        The Ssezibwa Falls are located 35km east of Kampala in district of Mukono district. The spectacular waterfalls are believed to have been born by humans many hundred years ago. The falls are one of the most spiritual and cultural centers where many natives flock for blessings, wealth, and fortunes. A traditional healer performs ceremonies for those seeking love, children, a successful business deal or a good harvest.
                        <br />
                        <br />
                        The Falls are on the Ssezibwa River which flows from the wetlands between L. Victoria and L. Kyoga. Ssezibwa Falls are about seven meters high and with the sweet hissing sound created by the falling water forms beautiful scenery. This is complemented by the natural vegetation cover and the undulating and steep rocks over which the waters fall. The site has several bird species which present a wonderful opportunity for bird watching.
                        <br />
                        <br />
                        The falls have cultural and traditional bearing in the region and to the Baganda in particular due the myths behind the formation of the Ssezibwa River. It is believed that this river, together with another called Bwanda was born of a Woman on her way to place called Kavuma Bukunja. It is because of this that it is a reserved Buganda Heritage Site.
                        <br />
                        <br />
                        Activities at Ssezibwa Falls

                        Ssezibwa Falls is an excellent and convenient place where one can enjoy and experience the wonders of nature. Among the activities a tourist would enjoy include; Camping, Nature-guided walks, Bird watching, Boat drive/canoeing, Community walks, Hiking, Mountain biking, Primate Walks and Rock climbing.
                    </p>

                    <video
                        src={video}
                        className='the-video'
                        autoPlay
                        loop
                        muted
                    ></video>
                </div>
            </div>
        </Brand>
    )
}

export default Bannar;
