import React from 'react';
import JoinUs from '../../../shared/join/join';
import Describe from './descript';
import logo from '../../../assets/ssezibwaFallsOrig.png';


const Description = () => {
    return (
        <Describe>
            <JoinUs />

            <div className='small_descipt'>
                <p>Located 32 Km along the Kampala - Jinja highway. It is one of Uganda’s
                    hidden cultural treasures with one part owned by Buganda Kingdom and
                    another by the Anglican Church of Uganda. It takes approximately 30 minutes drive from the capital Kampala depending on the traffic flowing out of
                    the city.
                    <br /><br />
                    The sezibwa falls tour is usually part of the famous Jinja one day
                    tour that takes you to Mabira forest and source of river nile. You can alternatively have this tour included on your longer Uganda safaris like the gorilla safaris, wildlife safari, birding safari or the cultural safaris.
                    The source of Sezibwa river is in Buikwe District and runs for a total length
                    of about 150 km from the source to the end. </p>

                <img className='logo' src={logo} alt='logo' />
            </div>

            <div className='know__us'>
                <div className='content'>
                    <div className='text'>
                        <h2>Take a deep dive into our activities</h2>
                        <p>
                            Ssezibwa Falls is an excellent and convenient place where one can enjoy and experience the wonders of nature. Among the activities a tourist would enjoy include; Camping, Nature-guided walks, Bird watching, Boat drive/canoeing, Community walks, Hiking, Mountain biking, Primate Walks and Rock climbing
                        </p>
                    </div>

                    {/* <button className='btn'>Book Now</button> */}
                </div>
            </div>
        </Describe>
    )

}

export default Description;
